// @ts-nocheck
/* eslint-disable */

import { t, validateNonEmpty, ensureIsArray  } from '@superset-ui/core';
import { ControlPanelConfig, sections, sharedControls, ColumnOption, datasourceControl, columnChoices } from '@superset-ui/chart-controls';
import { handlebarsTemplateControlSetItem } from './coding/handlebarTemplate';
import { styleControlSetItem } from './coding/style';

const all_columns: typeof sharedControls.groupby = {
  type: 'SelectControl',
  label: t('Latitude/Longitude'),
  description: t('Spalten mit den Breitengraden auswählen.'),
  default: null,
  mapStateToProps: (state: ControlPanelState) => ({
    choices: columnChoices(state.datasource),
  }),
};


const config: ControlPanelConfig = {
  controlPanelSections: [
    sections.legacyRegularTime,
    {
      label: t('Query'),
      expanded: true,
      controlSetRows: [
        [
          {
            name: 'lon',
            config: {
              type: 'SelectControl',
              label: t('Longitude'),
              description: t('Column containing longitude data'),
              validators: [validateNonEmpty],
              mapStateToProps: (state: ControlPanelState) => ({
                choices: columnChoices(state.datasource),
              }),
            },
          },
        ],
        [
          {
            name: 'lat',
            config: {
              type: 'SelectControl',
              label: t('Latitude'),
              description: t('Column containing latitude data'),
              validators: [validateNonEmpty],
              mapStateToProps: (state: ControlPanelState) => ({
                choices: columnChoices(state.datasource),
              }),
            },
          },
        ],
        [
          {
            name: 'markerName',
            config: {
              ...sharedControls.groupby,
              label: t('Name der Markierungen'),
              description: t('Wähle die Spalte mit den Markierungsnamen aus'),
              validators: [validateNonEmpty],
            },
          },
        ],
        ['adhoc_filters'],
        [
          {
            name: 'row_limit',
            config: sharedControls.row_limit,
          },
        ],
      ],
    },
    {
      label: t('Customize'),
      expanded: true,
      controlSetRows: [
        [handlebarsTemplateControlSetItem],
        [styleControlSetItem],
      ],
    },  
  ],
};

export default config;
